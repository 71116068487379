.flag-sprite {
  width: 32px;
  scale: 0.8;
}

.flag-sprite-Belgium {
  background: url(/assets/images/flags/belgium.svg) no-repeat;
}

.flag-sprite-Czech-Republic {
  background: url(/assets/images/flags/czech-republic.svg) no-repeat;
}

.flag-sprite-Denmark {
  background: url(/assets/images/flags/denmark.svg) no-repeat;
}

.flag-sprite-Estonia {
  background: url(/assets/images/flags/estonia.svg) no-repeat;
}

.flag-sprite-Finland {
  background: url(/assets/images/flags/finland.svg) no-repeat;
}

.flag-sprite-Germany {
  background: url(/assets/images/flags/germany.svg) no-repeat;
}

.flag-sprite-Hungary {
  background: url(/assets/images/flags/hungary.svg) no-repeat;
}

.flag-sprite-Latvia {
  background: url(/assets/images/flags/latvia.svg) no-repeat;
}

.flag-sprite-Lithuania {
  background: url(/assets/images/flags/lithuania.svg) no-repeat;
}

.flag-sprite-Luxembourg {
  background: url(/assets/images/flags/luxembourg.svg) no-repeat;
}

.flag-sprite-Netherlands {
  background: url(/assets/images/flags/netherlands.svg) no-repeat;
}

.flag-sprite-Norway {
  background: url(/assets/images/flags/norway.svg) no-repeat;
}

.flag-sprite-Poland {
  background: url(/assets/images/flags/poland.svg) no-repeat;
}

.flag-sprite-Romania {
  background: url(/assets/images/flags/romania.svg) no-repeat;
}

.flag-sprite-Sweden {
  background: url(/assets/images/flags/sweden.svg) no-repeat;
}

.flag-sprite-Switzerland {
  background: url(/assets/images/flags/switzerland.svg) no-repeat;
}
