py-mail-form {
  .mail-form {
    &.mailform-newsletter {
      max-width: none;
      border-top: none !important;
      background-image: url('../../assets/images/lines-pattern.jpg');
      background-size: contain;

      > form {
        color: var(--py-color-grey-200) !important;
        background-color: unset !important;
        padding: 0 !important;
        margin-top: 64px !important;
        margin-bottom: 64px !important;

        .lead-signup-label {
          font-family: var(--py-font-secondary-bold);
        }

        .sub-signup-label {
          padding-bottom: 16px !important;
        }

        .email-container {
          display: flex;
          position: unset !important;
          width: unset !important;
          left: unset !important;
          top: unset !important;

          py-reactive-form-input-field {
            margin-right: spacer(2);
          }

          @include media-breakpoint-down(md) {
            flex-direction: column;
          }

          py-action-button {
            button {
              @extend .btn-primary;
            }

            @include media-breakpoint-down(md) {
              max-width: none !important;
              margin-top: spacer(2);
            }
          }
        }

        .bottom-signup-label {
          position: unset !important;
          width: unset !important;
          left: unset !important;
          top: unset !important;
          margin-top: spacer(3);
        }

        a {
          color: var(--py-color-info-400) !important;
        }
      }
    }
  }
}
